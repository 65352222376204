import React from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import { AddShoppingCart, ShoppingCart } from '@mui/icons-material';
import { useApi } from '../../context/ApiProvider';

const PurchaseButton = ({ id, purchase_url, is_purchased }) => {
  const api = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const handlePurchaseClick = async () => {
    // Open the new tab
    window.open(purchase_url, '_blank');

    // Perform the purchase tracking
    try {
      const response = await api.put(`/favs/track/${id}/purchase`);
      if (response.ok) {
        enqueueSnackbar(`Track purchased!`);
      }
    } catch (error) {
      console.error('Error during purchase:', error);
    }
  };

  return (
    <Button
      variant="contained"
      onClick={handlePurchaseClick}
      startIcon={is_purchased ? <ShoppingCart /> : <AddShoppingCart />}
      sx={{
        borderLeft: '1px solid',
      }}
    >
      {is_purchased ? 'Purchased' : 'Buy'}
    </Button>
  );
};

PurchaseButton.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  purchase_url: PropTypes.string,
  is_purchased: PropTypes.boolean,
};

export default PurchaseButton;
